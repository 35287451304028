const experience = new Date().getFullYear() - 2020;

export const profileEN = {
  name: "Guilherme Viana",
  head: "Fullstack Software Engineer",
  resume: `<p>Hi! I am a Full Stack Software Engineer with over ${experience} years of experience in the tech industry. My passion lies in leveraging technology to make a positive impact on society and drive progress forward.</p>
  <p>Specializing in Python and React, I bring a wealth of experience and expertise to my work. I hold an AWS Cloud Practitioner certification and am known for my collaborative skills and commitment to crafting robust solutions. My approach to problem-solving is characterized by clear communication, proactivity, flexibility, and a readiness to assist others. I thrive on tackling complex challenges and solving real-world problems, always striving to improve both my technical and communication skills.</p>
  <p>Outside of work, I maintain a disciplined lifestyle. I hold a purple belt in karate and am an avid swimmer. My favorite books include "How to Win Friends and Influence People" and "Outwitting the Devil". I'm a passionate supporter of the Corinthians soccer team and enjoy the excitement of going to the stadium. I also have a love for Japanese food, which is my favorite cuisine. Fluent in two languages, I'm always eager to learn and grow.</p>`,
  project_title: "Projects",
  projects: [
    {
      title: "PlayTogether hub",
      description:
        "Say goodbye to solo gaming and hello to endless fun with PlayTogether hub. Discover fellow gamers who share your gaming tastes and preferences",
      projectSkills: [
        "Node.js",
        "Express.js",
        "Typescript",
        "React.js",
        "Tailwindcss",
        "Prisma orm",
        "AWS",
        "Vercel",
        "Docker",
        "PostgreSQL",
      ],
      video: "https://www.playtogetherhub.gvianadev.com/",
      code: "https://github.com/guirlviana/playtogetherhub",
    },
    {
      title: "HelpInvestor",
      description:
        "Add your asset from Brazilian stock exchange set a buy and sell price. When the price targets we going to send you a notification",
      projectSkills: [
        "Python",
        "Django",
        "Django Rest Framework",
        "AWS",
        "AWS Lambda",
        "AWS EventBridge",
        "AWS SNS",
        "AWS RDS",
        "Docker",
        "PostgreSQL",
        "Serverless",
      ],
      video: "https://github.com/guirlviana/helpinvestor",
      code: "https://github.com/guirlviana/helpinvestor",
    },
  ],
  archivedProjects: [
    {
      title: "Email Manager",
      description:
        "Email Manager is an automatic program for sending bulk emails used in many advertising campaigns and selling info products by entrepreneurs all over Brazil",
      video: "https://youtu.be/2LdVS6rmjbU",
      code: "",
    },
    {
      title: "Playlist YouTube Downloader",
      description:
        "Tool to export your created music playlist on YouTube directly to your computer",
      video: "https://youtu.be/przqm-3JCQI",
      code: "",
    },
    {
      title: "Order restaurant system",
      description:
        "System developed for the restaurant industry with complete order control functionality and non-tax coupon issuer for customers",
      video: "https://youtu.be/XgkfdkxnWK0",
      code: "",
    },
    {
      title: "World-API",
      description:
        "Application aimed at learning for all ages, allows you to view data from any country in the world quickly and efficiently",
      video: "",
      code: "https://github.com/guirlviana/World-API",
    },
    {
      title: "OLX Web Scraping",
      description:
        "WebScraping using Python and Selenium with Excel. The program carries out price surveys for a particular product and creates a complete spreadsheet for analysis",
      video: "",
      code: "https://github.com/guirlviana/Web-Scraping-Excel-Selenium",
    },
    {
      title: "Backend of Evernote App",
      description: "backend project based on the Evernote application",
      video: "",
      code: "https://github.com/guirlviana/Evernote",
    },
    {
      title: "Best quote",
      description:
        "Program that lists the daily quotation of the American Dollar and the Euro, the values ​​are automatically withdrawn from the Central Bank of Brazil",
      video: "",
      code: "https://github.com/guirlviana/Cotacoes-nota-10",
    },
    {
      title: "Virtual phonebook",
      description: "Smart phonebook online",
      video: "",
      code: "https://github.com/guirlviana/Smart-Contacts-book",
    },
    {
      title: "BICCO",
      description:
        "BICCO API and database (Application that connects self-employed workers with people looking for a fast and well-evaluated service) made to complete the technical course in Systems Development at Etec São Paulo",
      video: "",
      code: "https://github.com/guirlviana/Bicco",
    },
    {
      title: "Good blog for you",
      description: "Custom blog to create and manage your posts",
      video: "https://youtu.be/ZfHioI0azKU",
      code: "https://github.com/guirlviana/Django-Blog",
    },
    {
      title: "Quote of the day",
      description: "How about a phrase of the day today?",
      video: "https://guirlviana.github.io/frasedodia.github.io/",
      code: "https://github.com/guirlviana/frasedodia.github.io",
    },
    {
      title: "ADDRESS-API",
      description: "Project to save data from a zip code using viacep api",
      video: "",
      code: "https://github.com/guirlviana/CEP-API",
    },
    {
      title: "TODO List",
      description: "Todolist app to organize your tasks",
      video: "https://youtu.be/7Ink3ejHE6A",
      code: "https://github.com/guirlviana/to-do-list",
    },
  ],
  btn_demo: "Demo",
  btn_code: "Source Code",
  skill_title: "My stacks",
  skills: [
    {
      title: "Programming languages",
      items: [
        {
          path: "python",
          name: "Python",
        },
        {
          path: "javascript",
          name: "Javascript",
        },
        {
          path: "typescript",
          name: "Typescript",
        },
        {
          path: "nodejs",
          name: "Node.js",
        },
      ],
    },
    {
      title: "Frameworks & Libraries",
      items: [
        {
          path: "django",
          name: "Django",
        },
        {
          path: "drf",
          name: "Django Rest Framework (DRF)",
        },
        {
          path: "flask",
          name: "Flask",
        },
        {
          path: "react",
          name: "React.js",
        },
        {
          path: "express",
          name: "Express.js",
        },
      ],
    },
    {
      title: "Cloud services",
      items: [
        {
          path: "aws",
          name: "AWS",
        },
        {
          path: "cloudpractitioner",
          name: "AWS Cloud Practitioner",
        },
      ],
    },
    {
      title: "Devops & Tools",
      items: [
        {
          path: "rabbitmq",
          name: "RabbitMQ",
        },
        {
          path: "docker",
          name: "Docker",
        },
        {
          path: "linux",
          name: "Linux",
        },
        {
          path: "selenium",
          name: "Selenium",
        },
      ],
    },
    {
      title: "Databases",
      items: [
        {
          path: "mysql",
          name: "MySQL",
        },
        {
          path: "postgresql",
          name: "PostgreSQL",
        },
        {
          path: "mongodb",
          name: "MongoDB",
        },
        {
          path: "redis",
          name: "Redis",
        },
      ],
    },
  ],
  footer: "All rights reserved",
};

export const profilePtBR = {
  name: "Guilherme Viana",
  head: "Desenvolvedor FullStack",
  resume: `Olá! Sou desenvolvedor FullStack com ${experience}+ anos de experiência na
    área. Tenho experiência atuando em projetos Back-End e Front-End.
    Sou Apaixonado por tecnologia, gosto de estudar assuntos sobre:
    Arquitetura de Software, testes automatizados, linguagens de programação e frameworks`,
  project_title: "Projetos",
  projects: [
    {
      title: "Email em massa",
      description:
        "Email Manager é um programa de envio automático de e-mails em massa utilizado em muitas campanhas de anúncios e venda de infoprodutos por empreendedores em todo Brasil",
      video: "https://youtu.be/2LdVS6rmjbU",
      code: "",
    },
    {
      title: "Exportador de Playlist",
      description:
        "Ferramenta para exportar sua playlist de músicas criada no YouTube diretamente para seu computador",
      video: "https://youtu.be/przqm-3JCQI",
      code: "",
    },
    {
      title: "Sistema de pedidos para restaurantes",
      description:
        "Sistema desenvolvido para o ramo de restaurantes com funcionalidades completas de controle de pedidos e emissor de cupom não fiscal para clientes",
      video: "https://youtu.be/XgkfdkxnWK0",
      code: "",
    },
    {
      title: "Mundo-API",
      description:
        "Aplicativo voltado para o aprendizado de todas as idades, permite visualizar dados de qualquer país no mundo de forma rápida e eficiente",
      video: "",
      code: "https://github.com/guirlviana/World-API",
    },
    {
      title: "OLX Extração de dados",
      description:
        "WebScraping utilizando Python e Selenium com Excel. O programa realiza pesquisas de preços de determinado produto e cria uma planilha completa para análises",
      video: "",
      code: "https://github.com/guirlviana/Web-Scraping-Excel-Selenium",
    },
    {
      title: "Backend do aplicativo Evernote",
      description: "Projeto backend baseado no aplicativo Evernote",
      video: "",
      code: "https://github.com/guirlviana/Evernote",
    },
    {
      title: "Cotações nota 10",
      description:
        "Programa que lista a cotação diária do Dólar Americano e do Euro, os valores são retirados automaticamente do Banco Central do Brasil",
      video: "",
      code: "https://github.com/guirlviana/Cotacoes-nota-10",
    },
    {
      title: "Agenda virtual",
      description: "Agenda inteligente de contatos online",
      video: "",
      code: "https://github.com/guirlviana/Smart-Contacts-book",
    },
    {
      title: "BICCO",
      description:
        "API e banco de dados do BICCO (Aplicativo que conecta trabalhadores autonômos com pessoas que buscam um serviço rápido e bem avaliado) feitos para o meu TCC do curso técnico em Desenvolvimento de Sistemas da Etec de Itaquera",
      video: "",
      code: "https://github.com/guirlviana/Bicco",
    },
    {
      title: "Seu blog aqui",
      description: "Blog personalizado para criar e gerenciar seus posts",
      video: "https://youtu.be/ZfHioI0azKU",
      code: "https://github.com/guirlviana/Django-Blog",
    },
    {
      title: "Frase do dia",
      description: "Que tal uma frase do dia hoje?",
      video: "https://guirlviana.github.io/frasedodia.github.io/",
      code: "https://github.com/guirlviana/frasedodia.github.io",
    },
    {
      title: "CEP-API",
      description:
        "Projeto para salvar dados a partir de um cep utilizando api viacep",
      video: "",
      code: "https://github.com/guirlviana/CEP-API",
    },
    {
      title: "Lista de tarefas",
      description: "Aplicativo de todolist para organizar suas tarefas",
      video: "https://youtu.be/7Ink3ejHE6A",
      code: "https://github.com/guirlviana/to-do-list",
    },
  ],
  btn_demo: "Demonstração",
  btn_code: "Código Fonte",
  skill_title: "Habilidades",
  skills: [
    {
      path: "python",
      name: "Python",
    },
    {
      path: "javascript",
      name: "Javascript",
    },
    {
      path: "nodejs",
      name: "Node.js",
    },
    {
      path: "django",
      name: "Django",
    },
    {
      path: "flask",
      name: "Flask",
    },
    {
      path: "react",
      name: "React",
    },
    {
      path: "aws",
      name: "AWS",
    },
    {
      path: "cloudpractitioner",
      name: "AWS Cloud Practitioner",
    },
    {
      path: "rabbitmq",
      name: "RabbitMQ",
    },
    {
      path: "docker",
      name: "Docker",
    },
    {
      path: "linux",
      name: "Linux",
    },
    {
      path: "mysql",
      name: "MySQL",
    },
    {
      path: "postgresql",
      name: "PostgreSQL",
    },
    {
      path: "mongodb",
      name: "MongoDB",
    },
    {
      path: "redis",
      name: "Redis",
    },
    {
      path: "selenium",
      name: "Selenium",
    },
  ],
  footer: "Todos os Direitos reservados",
};
